.contenedorMain {
    margin: 0 auto;
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 80px 1fr;
    grid-template-areas:
        "header header"
        "contenido contenido"
        "footer footer";
}

.contenedorMain header {
    grid-area: header;
    display: flex;
    justify-content: flex-start;
    padding: 10px 25px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 999;
}

.contenedorMain .logo,
.containerNavMobile .logo {
    width: 100px;
    height: 50px;
    margin: auto;
    cursor: pointer;
}

.Nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.navContacto {
    display: flex;
    background-color: var(--primary-color);
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
}

.Nav .linkDiv {
    margin: auto;
    display: flex;
}

.Nav .linkDiv>a:link {
    cursor: pointer;
    font-weight: bold;
    color: #000 !important;
    text-transform: none;
}

.Nav .linkDiv>a:hover {
    color: var(--primary-color) !important;
}

.Nav .linkDiv>a:visited {
    color: #000;
}

.linkDiv>a:link:not(.linkDiv>a:link:last-child) {
    margin-right: 7rem;
}

.linkLogo {
    display: flex;
    justify-content: center;
}

.contenedorMain main {
    grid-area: contenido;
    background-color: #E5E5E5;
}

.containerNavMobile {
    height: 100%;
    padding: 10px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);
}

.menuHeader {
    display: none;
}

footer {
    grid-area: footer;
    background-color: var(--footer-bg);
    padding: 30px 25px;
    color: #D9D9D9;
}

.footerContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footerContainer div {
    width: 400px;
    display: flex;
    flex-direction: column;
}

.footerContainer div a {
    text-decoration: none;
    text-transform: none;
    color: #D9D9D9 !important;
}

.footerContainer div>p:nth-child(1) {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
}

.enlaces a:link:not(.enlaces>a:link:last-child) {
    margin-bottom: 20px;
}

.contacto a:link:not(.contacto>a:link:last-child) {
    margin-bottom: 20px;
}

.footerContainer div:not(.footerContainer>div:nth-child(3)) {
    margin-bottom: 30px;
}

@media screen and (max-width: 750px) {
    .contenedorMain {
        grid-template-areas:
            "header"
            "contenido"
            "footer";

        grid-template-columns: 100%;
        grid-template-rows: 60px 1fr;
        height: unset;
    }

    .contenedorMain .Nav {
        display: none;
    }

    .contenedorMain header {
        padding: 10px 20px;
        align-items: center;
    }

    .menuHeader {
        display: flex;
    }

    .footerContainer div>p:nth-child(2) {
        text-align: justify;
    }

    .contenedorMain .linkLogo {
        margin: 0 auto 0 auto;
    }

    .containerNavMobile {
        width: 150px;
    }

    .containerNavMobile .logo {
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid #adacac;
    }

    .containerNavMobile a:link {
        cursor: pointer;
        font-weight: bold;
        color: #000 !important;
        text-transform: none;
        font-size: 18px;
    }

    .containerNavMobile a:link:not(.containerNavMobile a:link:last-child) {
        margin-bottom: 20px;
    }

    .containerNavMobile a:visited {
        color: #000;
    }

    .navContacto:visited {
        color: #fff !important;
    }

    .navContacto {
        justify-content: center;
        margin-top: auto;
        font-weight: unset !important;
    }
}