.galeriaContainer {
    background-color: #fff;
    padding: 10px 25px 30px 25px;
}

.galeriaContainer h1 {
    text-align: center;
    color: var(--primary-color);
    font-size: 28px;
}

.galeria {
    margin-top: 20px;
    display: grid;
    gap: 40px 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    min-height: 60vh;
}

.galeria .proyect>img {
    width: 200px;
    height: 200px;
    border-radius: 8px 8px 0 0;
}

.galeria .proyect {
    border-radius: 8px;
    margin: 0 auto;
    -webkit-box-shadow: -2px 3px 31px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -2px 3px 31px -3px rgba(0, 0, 0, 0.75);
    box-shadow: -2px 3px 31px -3px rgba(0, 0, 0, 0.75);
    padding-bottom: 5px;
    height: fit-content;
}

.galeria .proyect>p {
    color: var(--txt_sub);
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.loaderContainer {
    height: 60vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.loader {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
}

.imgProyectModal {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

/* LOADER 1 */

#loader-1:before,
#loader-1:after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 10px solid transparent;
    border-top-color: var(--primary-color);
}

#loader-1:before {
    z-index: 100;
    animation: spin 1s infinite;
}

#loader-1:after {
    border: 10px solid #ccc;
}

.proyect .verImagenes {
    border: 2px solid var(--primary-color);
    color: var(--primary-color) !important;
    width: fit-content;
    font-weight: unset !important;
    border-radius: 8px;
    padding: 5px 10px;
    margin: 5px auto 0 auto;
    font-size: 16px !important;
    cursor: pointer;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px) {
    .galeria {
        grid-template-columns: repeat(1, 1fr);
        min-height: 50vh;
    }

    .loaderContainer {
        height: 50vh;
    }

    .imgProyectModal {
        width: 100%;
    }
}