.slider {
    width: 100%;
    height: 70vh;
    overflow: hidden;
}

.slider img {
    width: 100%;
    opacity: 0;
    object-fit: cover;
    height: 70vh;
    position: absolute;
    transition: opacity 1s;
}

.sliderInfo {
    position: absolute;
    top: 35%;
    left: 25%;
}

.sliderInfo h1 {
    color: #fff;
    font-size: 3rem;
}

.sliderInfo p {
    background-color: var(--primary-color);
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: fit-content;
    margin-top: 10px;
}

.sliderBtn a {
    color: #fff !important;
}

.default-nav {
    display: none !important;
}

.progressContainer {
    display: flex;
    background-color: var(--primary-color);
    justify-content: space-evenly;
    padding: 15px 0;
}

.progressContainer div {
    display: flex;
    color: #fff;
    align-items: center;
    width: 300px;
}

.progressContainer div p:nth-child(1) {
    font-size: 40px;
    font-weight: bold;
    margin-right: 10px;
}

.progressContainer div p:nth-child(2) {
    width: 90px;
    font-size: 18px;
}

.serviciosContainer {
    padding: 30px 25px 40px 25px;
}

.serviciosContainer h2 {
    text-align: center;
    font-size: 26px;
}

.serviciosContainer .servicios {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.serviciosContainer .servicios>div {
    border-radius: 8px;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    -webkit-box-shadow: -1px 3px 29px 2px rgba(148, 148, 148, 1);
    -moz-box-shadow: -1px 3px 29px 2px rgba(148, 148, 148, 1);
    box-shadow: -1px 3px 29px 2px rgba(148, 148, 148, 1);
    align-items: center;
    margin-top: 40px;
}

.serviciosContainer .servicios>div>p {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

.proyectosContainer {
    padding: 30px 25px;
    background-color: var(--fade-primary);
}

.proyectosContainer h2 {
    text-align: center;
}

.proyectosContainer .proyectos {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    flex-wrap: wrap;
}

.proyectosContainer .proyectos>div {
    display: flex;
    flex-direction: column;
}

.proyectosContainer .proyectos>div>img {
    width: 250px;
    height: 250px;
    border-radius: 8px;
}

.proyectosContainer .proyectos>div>p {
    color: var(--txt_sub);
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
}

.irProyectos {
    color: #fff;
    background-color: var(--primary-color);
    padding: 10px;
    border-radius: 8px;
    width: fit-content;
    margin: 20px auto 0 auto;
    cursor: pointer;
}

.irProyectos a:visited {
    color: #fff;
}

.nosotrosContainer {
    background-color: #fff;
    display: flex;
    padding: 30px 25px;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.nosotrosContainer>div:nth-child(2) {
    width: 30%;
}

.nosotrosContainer>div:nth-child(2) h2 {
    margin-bottom: 20px;
}

.creditoContainer {
    background-color: var(--primary-color);
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}

.creditoContainer h2 {
    margin-bottom: 20px;
}

.creditoContainer .saberMas {
    background-color: #fff;
    color: var(--primary-color);
    padding: 10px;
    margin-top: 20px;
    border-radius: 8px;
}

@media screen and (max-width: 750px) {
    .progressContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .progressContainer div {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .progressContainer div p:nth-child(1) {
        margin-right: 0;
    }

    .progressContainer div p:nth-child(1):not(.progressContainer>div:nth-child(1)>p) {
        margin-top: 10px;
    }

    .progressContainer div p:nth-child(2) {
        width: fit-content;
    }

    .slider,
    .slider img {
        height: 60vh;
    }

    .sliderInfo {
        top: 30%;
        left: 20%;
    }

    .sliderInfo h1 {
        font-size: 2rem;
    }

    .nosotrosContainer {
        flex-direction: column-reverse;
    }

    .nosotrosContainer>div:nth-child(2) h2 {
        text-align: center;
    }

    .nosotrosContainer>div:nth-child(2) {
        width: unset;
        text-align: justify;
        margin-bottom: 10px;
    }

    .creditoContainer p {
        text-align: center;
    }

    .proyectosContainer .proyectos>div:not(.progressContainer .proyectos>div:nth-child(3)) {
        margin-bottom: 20px;
    }

    .irProyectos {
        margin: 0 auto 0 auto;
    }
}