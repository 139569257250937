.btn {
    border-radius: 10px !important;
    font-family: "Poppins", sans-serif !important;
    font-variant: normal !important;
    text-transform: none !important;
    padding: 10px 0 10px 0 !important;
    font-size: 20px !important;
}

.containerLogin .btn_submit,
.containerLogin .btn_loading {
    margin-top: 30px;
}

.btn_submit {
    color: #fff !important;
}

.containerLogin {
    height: 100vh;
    display: grid;
    background-color: var(--primary-color);
    justify-content: center;
    align-items: center;
}

.formLogin {
    display: grid;
    align-content: center;
    grid-template-columns: 1fr;
    background-color: var(--bg-color);
    padding: 30px;
    border-radius: 10px;
    width: 350px;
}

.containerLogin h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 3px;
}

.txt_sub {
    font-size: 15px;
    color: var(--txt_sub);
    margin-bottom: 24px;
}

.containerLogin .logo {
    margin: auto;
    margin-bottom: 20px;
    width: 190px;
    height: 100px;
}

@media screen and (max-width: 750px) {
    .containerLogin {
        background-color: var(--bg-color);
        height: 86vh;
    }

    .formLogin {
        padding: 20px;
    }

    .containerLogin .logo {
        margin-bottom: 70px;
    }

    .containerLogin .btn_submit,
    .containerLogin .btn_loading {
        margin-top: 70px;
    }
}