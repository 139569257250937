@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

* {
  margin: 0;
  padding: 0;
}

:root {
  --bg-color: #f4f5f5;
  --primary-color: #26BEEF;
  --color-link: #9898a0;
  --txt_sub: #555454;
  --footer-bg: #2A2835;
  --fade-primary: rgba(38, 190, 239, 0.2);
}

body {
  font-family: "Poppins", sans-serif !important;
  text-decoration: none;
  background-color: var(--bg-color);
}

a {
  text-decoration: none;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--bg-color);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
  border-radius: 10px;
}