.contactoContainer {
    background-color: #fff;
    padding: 0 25px 30px 25px;
}

.contactoContainer h1 {
    text-align: center;
    color: var(--primary-color);
}

.contactoPage {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.contactoPage .info,
.contactoPage .form {
    display: flex;
    flex-direction: column;
}

.contactoPage .info a:link,
.contactoPage .info a:visited {
    color: #000;
}

.contactoPage .info a:link {
    margin-bottom: 20px;
}

.contactoPage .info .icon {
    color: var(--primary-color);
}

.contactoPage .info .mapa {
    width: 100%;
    height: 400px;
    margin-top: 20px;
}

.form .submitBtn {
    color: #fff;
    background-color: var(--primary-color);
    padding: 10px;
    border: none;
    outline: none;
    font-family: "Poppins", sans-serif !important;
    border-radius: 8px;
    font-size: 20px;
    margin-top: 10px;
}

.form {
    justify-content: center;
}

.form .input {
    margin-bottom: 30px;
    width: 350px;
}

.form .formTitle {
    color: var(--primary-color);
    margin-bottom: 20px;
}

@media screen and (max-width: 750px) {
    .form {
        width: 100%;
    }

    .form .formTitle {
        margin-top: 30px;
    }

    .form .input {
        width: 100%;
    }
}