.contenedorMainAdmin {
    margin: 0 auto;
    display: grid;
    height: 100vh;

    grid-template-columns: 210px 1fr;
    grid-template-rows: 60px 1fr;
    grid-template-areas:
        "nav header"
        "nav contenido";
}

.contenedorMainAdmin header {
    grid-area: header;
    display: flex;
    justify-content: flex-end;
    padding: 10px 25px;
    background-color: var(--bg-color);
    border-radius: 0 0 8px 8px;
}

.contenedorMainAdmin .logo,
.containerNavMobileAdmin .logo {
    width: 150px;
    height: 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d2d2d2;
}

.contenedorMainAdmin main {
    grid-area: contenido;
    background-color: #E5E5E5;
    padding: 25px 25px 0 25px;
    overflow-y: scroll;
}

.contenedorMainAdmin .containerNavAdmin,
.containerNavMobileAdmin {
    grid-area: nav;
    padding: 10px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);
}

.containerNavMobileAdmin {
    height: 100%;
}

.containerNavAdmin .iconGroup,
.containerNavMobileAdmin .iconGroup {
    display: flex;
    margin-bottom: 20px;
}

.iconGroup svg,
.iconGroup img {
    margin-right: 15px;
}

.iconGroup svg,
.iconGroup p {
    color: var(--txt_sub);
}

.iconGroup .link-active,
.iconActive {
    color: var(--primary-color) !important;
}

.iconGroup.cerrarSesion p {
    color: var(--color-link) !important;
}

.iconGroup.cerrarSesion {
    padding-top: 10px;
    border-top: 1px solid #d2d2d2;
    margin-top: auto;
    margin-bottom: 0;
    cursor: pointer;
}

.userInfo {
    display: flex;
}

.profile {
    display: flex;
    margin-right: 10px;
}

.profile>p:nth-child(1) {
    color: #fff;
    font-weight: bold;
    padding: 10px;
    background-color: var(--primary-color);
    border-radius: 50px;
    margin-right: 10px;
}

.profile_info>p:nth-child(1) {
    font-size: 145x;
}

.profile_info>p:nth-child(2) {
    font-size: 14px;
    color: var(--txt_sub);
}

.menuHeader {
    display: none;
}

@media screen and (max-width: 750px) {
    .contenedorMainAdmin {
        grid-template-areas:
            "header"
            "contenido";

        grid-template-columns: 1fr;
        grid-template-rows: 60px 1fr;
    }

    .contenedorMainAdmin .containerNavAdmin {
        display: none;
        position: absolute;
        z-index: 10;
    }

    .contenedorMainAdmin header {
        justify-content: flex-start;
        padding: 10px 20px;
    }

    .userInfo {
        display: none;
    }

    .menuHeader {
        display: flex;
    }

}