.modal {
    bottom: 0 !important;
    top: unset !important;
}

.modal .MuiPaper-root {
    margin: 0 !important;
    width: 100%;
    border-radius: 20px 20px 0 0 !important;
}

.modal form {
    display: flex;
    flex-direction: column;
}

.modal .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
    padding-top: 12px !important;
}

.modal .textfield_group {
    display: flex;
    margin-bottom: 22px;
}

.modal .btn_eliminar {
    background-color: #f60000 !important;
}

@media only screen and (min-width: 750px) {
    .MuiSnackbar-root {
        bottom: 50px !important;
        left: auto !important;
        right: 23px !important;
    }

    .modal {
        top: 0 !important;
    }

    .modal .MuiPaper-root {
        border-radius: 20px !important;
        width: fit-content;
        max-width: calc(100% - 64px);
    }
}